import { Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';
import { Router } from '@angular/router';
import { UiNotificationService } from 'src/app/features/ui-notifications/ui-notification.service';
import { TranslateService } from '@ngx-translate/core';
import { JWTTokenService } from './jwt-token.service';
import { UserClient } from '../api-client';
import { AccountResultDto } from '../api-client';
import { onError, unwrap } from '../extensions';
import { UntilDestroy } from '@ngneat/until-destroy';

@Injectable({
  providedIn: 'root',
})
@UntilDestroy()
export class ImpersonateService {
  constructor(
    private readonly userClient: UserClient,
    private readonly jwtTokenService: JWTTokenService,
    private readonly translateService: TranslateService,
    private readonly notificationsService: UiNotificationService,
    private readonly router: Router,
    private readonly localStorage: LocalStorageService
  ) {}

  isImpersonateMode(): boolean {
    return (
      this.localStorage.get('impersonate_access_token') !== null &&
      this.localStorage.get('impersonate_refresh_token') !== null
    );
  }

  private impersonateUser(userId: number) {
    return this.userClient.impersonate(userId).pipe(
      unwrap<AccountResultDto>(),
      onError((err) =>
        this.notificationsService.error(
          this.translateService.instant(err.join(' '))
        )
      )
    );
  }

  impersonate(userId: number) {
    this.impersonateUser(userId).subscribe((response) => {
      this.localStorage.set(
        'impersonate_access_token',
        this.jwtTokenService.getToken()!
      );
      this.localStorage.set(
        'impersonate_refresh_token',
        this.jwtTokenService.getRefreshToken()!
      );
      this.jwtTokenService.setUserDetail(response);
      this.router.navigate(['/']);
    });
  }

  impersonateClient(userId: number, redirectUrl: string = '/') {
    this.impersonateUser(userId).subscribe((response) => {
      window.open(
        redirectUrl +
          '?access_token=' +
          response.accessToken +
          '&refresh_token=' +
          response.refreshToken
      );
    });
  }

  stopImpersonate() {
    const token: AccountResultDto = {
      accessToken: this.localStorage.get('impersonate_access_token')!,
      refreshToken: this.localStorage.get('impersonate_refresh_token')!,
    };

    this.localStorage.remove('impersonate_access_token');
    this.localStorage.remove('impersonate_refresh_token');

    this.jwtTokenService.setUserDetail(token);

    this.router.navigate(['/']);
  }
}
